
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
import { LoginInfos, RouteName, Course } from '@/models';
import LogoFiaNetworking from '@/assets/img/LogoFiaNetworking.svg';
import ButtonDefault from '@/components/shared/ButtonDefault.vue';

const {
  INICIO,
  ASSIGNMENT_OBJECT_MANAGE,
  COURSES,
  PROFILE,
} = RouteName;

@Component({
  name: 'Login',
  computed: {
    ...mapGetters('login', {
      isLogged: 'isLogged',
      isValid: 'isValid',
    }),
    ...mapGetters('user', {
      isStudent: 'isStudent',
      isFirstAccess: 'isFirstAccess',
      getUserCourses: 'getUserCourses',
      getUserCourseId: 'getUserCourseId',
    }),
  },
  methods: {
    ...mapActions('login', {
      login: 'login',
      setValidity: 'setValidity',
    }),
  },
  components: {
    LogoFiaNetworking,
    ButtonDefault,
  },
})
export default class Login extends Vue {
  public email = '';
  public senha = '';
  public invalidData = false;
  public loading = false;
  public link = '';

  public isLogged: boolean;
  public isValid: boolean;
  public login!: (data: LoginInfos) => Promise<boolean>;
  private isStudent: boolean;
  private isFirstAccess: boolean;
  private getUserCourses: Course[];
  private getUserCourseId: number;

  mounted() {
    if (this.isLogged && !this.isValid) this.goToHome();
  }

  public autenticar() {
    const data = { email: this.email, senha: this.senha };
    this.loading = true;
    this.login(data)
      .then((status: boolean) => {
        this.invalidData = !status;
        if (status) this.goToHome();
      })
      .finally(() => (this.loading = false));
  }

  public goToHome(): void {
    let name = ASSIGNMENT_OBJECT_MANAGE;
    let params = {};

    if (this.isStudent) {
      name = this.getUserCourses.length > 1
        ? COURSES
        : INICIO;

      if (this.isFirstAccess) name = PROFILE;
    }

    if (name === INICIO) params = { cursoId: this.getUserCourseId.toString() };

    this.$router.push({ name, params });
  }
}
